import React from "react";


import winLogo from "./static/logo/windows.png";
import macosLogo from "./static/logo/macos.png";
import linuxLogo from "./static/logo/linux.png";


const LinkWindows = (windows) =>{
  return window.location.href = windows;
}
const LinkMacos = (macos) =>{
  return window.location.href = macos;
}
const LinkLinux = (linux) =>{
  return window.location.href = linux;
}


const Software = ({ nome, img, windows, macos, linux }) => {
  const check = (windows, macos, linux) => {
    //Se o campo do windows tiver link
    if (windows !== "" && macos !== "" && linux !== "") {
      return React.createElement("div", { className: "software" }, [
        React.createElement("img", {src: winLogo, id:"logoSize", onClick:()=>LinkWindows(windows)}, null),
        React.createElement("img", { src: macosLogo, id:"logoSize", onClick:()=>LinkMacos(macos) },null),
        React.createElement("img", { src: linuxLogo, id:"logoSize", onClick:()=>LinkLinux(linux) },null),
      ]);
    } else if (windows !== "") {
      return React.createElement("div", { className: "" }, [
        React.createElement("img", { src: winLogo , id:"logoSize", onClick:()=>LinkWindows(windows)}, null),
      ]);
    }
  };
  return (
    <div id="software">
      <img style={{ height: 25 }} src={img} alt="" />
      <p id="nameSoftware">{nome}</p>
      {check(windows, macos, linux)}
    </div>
  );
};
export default Software;

//onClick={() => linked(windows)}
//window.location.href = windows;
/*


<button>windows</button>
<button>macos</button>
<button>linux</button>

*/
