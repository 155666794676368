import React from "react";
//Para injetar na div root
import ReactDom from "react-dom";
import { useState } from "react";

//CSS
import "./css/index.css";

//importar objetos "softwares é o nome do objeto no ficheiro softwares.js"
import { softwares } from "./softwares";

//Importar objeto Navbar
import Software from "./software";

import NavBarSetup from "./navbar";
import { navbaritems } from "./navbaritems";

import Social from "./social";

/*
function Greeting(){
  return <h4>Ola o meu nome é ricardo</h4>
}

const Greeting = () => {
  return React.createElement("h1", {}, "hello world");
};*/
//teste para tornar visivel o array names
//não é necessário
/*
const names = ["ricardo", "maria", "susan"];
const newNames = names.map((name) => {
  return <p>{name}</p>;
});
console.log(newNames);
*/
// ... vai listar todas as propriedades do objeto filmes para nao termos de por 1 a 1 ,
//estas são utilizadas na função Filme {id,nome,img}.
//A key={filmes.id} é um identificador para cada prop
function NavBar() {
  return (
    <div>
      {navbaritems.map((navbaritems) => {
        return <NavBarSetup {...navbaritems}></NavBarSetup>;
      })}
    </div>
  );
}

function SoftwareList() {
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <div>
      <input
        type="text"
        name="s"
        placeholder="Nome do software"
        onChange={(event) => {
          setSearchTerm(
            event.target.value
          ); /*Atribui a setSearchTerm o valor on change*/
        }}
      />

      <div id="softwareBox">
        {softwares
          .filter((val) => {
            //primeiro exec a função filter((val)=> ... e mapeia consoante o return se estiver vazio mapeia tudo
            if (searchTerm === "") {
              return val;
            } else if (
              val.nome.toLowerCase().includes(searchTerm.toLocaleLowerCase())
            ) {
              return val;
            }
          })
          .sort((a, b) => (a.nome > b.nome ? 1 : -1))
          .map((softwares) => {
            return <Software key={softwares.id} {...softwares}></Software>;
          })}
      </div>
    </div>
  );
}

function Footer() {
  return <Social />;
}

//local onde vai ser inserida o Greeting

ReactDom.render(<NavBar />, document.getElementById("divNavbar"));
ReactDom.render(<SoftwareList />, document.getElementById("root"));
ReactDom.render(<Footer />, document.getElementById("footer"));

/*
//map(filmes) -> Return o numero de items no array filmes

function Filmlist() {
  return (
    <div>
      {filmes.map((filmes) => {
        //Que campos queremos do objeto neste caso
        //nome, img
        const { nome, img } = filmes;
        return (
          <Filme>
            <h3>{nome}</h3>
            <img src={img} />
          </Filme>
        );
      })}
    </div>
  );
}
*/
