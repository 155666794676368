//importar imagens para o site
import img1 from "./static/vscode.png";
import img2 from "./static/steam.ico";
import img3 from "./static/discord.ico";
import img4 from "./static/afterburner.png";

//criado um array de objetos
export const softwares = [
  {
    id: 1,
    nome: "Visual Studio Code",
    img: img1,
    windows:
      "https://az764295.vo.msecnd.net/stable/c13f1abb110fc756f9b3a6f16670df9cd9d4cf63/VSCodeUserSetup-x64-1.61.1.exe",
    macos:
      "https://az764295.vo.msecnd.net/stable/6cba118ac49a1b88332f312a8f67186f7f3c1643/VSCode-darwin-universal.zip",
    linux: "https://az764295.vo.msecnd.net/stable/b3318bc0524af3d74034b8bb8a64df0ccf35549a/code_1.62.0-1635954068_amd64.deb",
  },
  {
    id: 2,
    nome: "Steam",
    img: img2,
    windows:
      "https://cdn.akamai.steamstatic.com/client/installer/SteamSetup.exe",
    macos: "",
    linux: "",
  },
  {
    id: 3,
    nome: "Discord",
    img: img3,
    windows:
      "https://dl.discordapp.net/distro/app/stable/win/x86/1.0.9003/DiscordSetup.exe",
    macos: "https://dl.discordapp.net/apps/osx/0.0.264/Discord.dmg",
    linux: "",
  },
  {
    id: 4,
    nome: "Afterburner",
    img: img4,
    windows:
      "https://download.msi.com/uti_exe/vga/MSIAfterburnerSetup.zip?__token__=exp=1635017555~acl=/*~hmac=f0aeb284e60eb15f164f0c539bfa3234a71117cdfa3ed4a77ad624fae00604e1",
    macos: "",
    linux: "",
  },
];
