import React from "react";
import linkedIn from "./static/social/linkedin.png";


const Social = () => {
  return (
  <div id="socialConnections">
    <img id="linkedIn" onClick={()=>window.location.href = "https://www.linkedin.com/in/ricardo-martins-706445220/"} src={linkedIn} />
  </div>
  );
};

export default Social;
