const NavBarSetup = ({ name, icon }) => {
    return (
        <div id="navBox">
            <div>
                <img style={{ height: 50 }} src={icon} alt="" />
            </div>
             <div>
                <h1>{name}</h1>
            </div>  
        </div>
    );
};

export default NavBarSetup;